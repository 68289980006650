<script lang="ts">
    interface TagCount {
        name: string;
        count: number;
    }

    export let tags: TagCount[];

    console.log(tags);
</script>

<div class="flex flex-wrap gap-2 items-center">
    {#each tags as tag}
        <a
            href="/tag/{tag.name}"
            class="px-2 py-1 text-xs rounded border border-mc-grey-600 text-mc-grey-400 hover:bg-mc-grey-800 transition-colors"
            tabindex="0"
            role="link"
            aria-label="View posts tagged with {tag.name}"
        >
            #{tag.name} <span class="text-mc-grey-500">({tag.count})</span>
        </a>
    {/each}
</div> 