<script lang="ts">
    import type { PageData } from "./$types";
    import PostPreview from "$lib/components/PostPreview.svelte";
    import TagOverview from "$lib/components/TagOverview.svelte";

    export let data: PageData;
</script>

<div class="max-w-2xl sm:max-w-full w-full mx-auto flex-grow">
    <div class="px-4 pt-1 pb-5 row center">
        <!-- <img src="/img/let-it-go.jpg" alt="logo" class="w-40"/> -->
        <img
            src="/img/head-anim.gif"
            alt="logo"
            class="w-40 mix-blend-lighten _rounded-full"
        />
    </div>

    <!-- Tag Overview -->
    <div class="px-4 mb-8">
        <TagOverview tags={data.allTags} />
    </div>  

    <!-- Thoughts Section -->
    <div class="px-4 mb-12">
        <h2 class="font-semibold tracking-wide text-mc-grey-400">
            thoughts...
        </h2>
        <div class="space-y-4 mt-4">
            {#each data.tagSections.thoughts as post}
                <PostPreview {post} />
            {/each}
        </div>
    </div>

    <!-- AI Section -->
    <div class="px-4 mb-12">
        <h2 class="font-semibold tracking-wide text-mc-grey-400">about ai</h2>
        <div class="space-y-4 mt-4">
            {#each data.tagSections.ai as post}
                <PostPreview {post} />
            {/each}
        </div>
    </div>

    <!-- Virtual Section -->
    <div class="px-4 mb-12">
        <h2 class="font-semibold tracking-wide text-mc-grey-400">
            and the virtual
        </h2>
        <div class="space-y-4 mt-4">
            {#if data.tagSections.virtual.length === 0}
                <div class="text-center py-8 text-mc-grey-500">
                    No posts yet in this category
                </div>
            {:else}
                {#each data.tagSections.virtual as post}
                    <PostPreview {post} />
                {/each}
            {/if}
        </div>
    </div>

    <hr class="border-mc-grey-600" />

    <div class="px-4 py-8 row center">
        <a
            href="/blog"
            class="px-4 py-2 bg-mc-grey-800 hover:bg-mc-grey-700 text-mc-grey-300 rounded transition-colors"
            tabindex="0"
            role="button"
            aria-label="View all blog posts"
        >
            View all posts
        </a>
    </div>
</div>
